import React, { Suspense } from "react";
import Providers from "~/providers";
import Routes from "~/routes";
import { BpSpinner } from "./components/BpSpinner";

export default () => (
  <Providers>
    <Suspense
      fallback={
        <div className="flex h-screen items-center justify-center">
          <div style={{ height: "100px" }}>
            <BpSpinner />
          </div>
        </div>
      }
    >
      <div className="flex flex-col bg-body min-h-screen overflow-hidden">
        <Routes />
      </div>
    </Suspense>
  </Providers>
);
