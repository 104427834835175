import { AnalyticsBrowser } from "@segment/analytics-next";
import { KratosClientError } from "./services/kratos/errors";
import { config } from "./const";

type TrackParams = Parameters<AnalyticsBrowser["track"]>;
type PageParams = Parameters<AnalyticsBrowser["page"]>;

type Status = "loading" | "available" | "error";

const trackingParamName = "s_id";
const trackingCookieName = "ajs_anonymous_id";

class Analytics {
  private status: Status = "loading";
  private readonly analyticsBrowser: AnalyticsBrowser;

  constructor() {
    this.analyticsBrowser = new AnalyticsBrowser();
  }

  public async track(...params: TrackParams): Promise<void> {
    await this.ensureLoaded();
    if (this.status !== "available") {
      return;
    }

    await this.analyticsBrowser.track(...params).catch((error) => {
      const eventName = params[0];
      console.error("Error tracking event", eventName, error);
    });
  }

  public async reportTrait(trait: Record<string, unknown>): Promise<void> {
    await this.ensureLoaded();

    if (this.status !== "available") {
      return;
    }

    await this.analyticsBrowser.identify(null, trait).catch((error) => {
      console.error("Error reporting trait", error);
    })
  }

  public async page(...params: PageParams): Promise<void> {
    await this.ensureLoaded();

    if (this.status !== "available") {
      return;
    }

    await this.analyticsBrowser.page(...params).catch((error) => {
      console.error("Error tracking page", error);
    });
  }

  public async trackKratosError(params: {
    error: KratosClientError;
    eventName: string;
    method: "password" | "google" | "github" | "microsoft" | "linkedin" | "facebook";
  }) {
    const { error, eventName, method } = params;
    await this.track(eventName, {
      method,
      reason: "kratos_error",
      kratos_error_id: error.id,
      kratos_error_type: error.type,
    });
  }

  private async ensureLoaded() {
    if (this.status === "loading") {
      await this.load();
    }
  }

  private async load() {
    try {
      await this.analyticsBrowser.load({ writeKey: config.segmentWriteKey });

      // Alias the user if they have a remote tracking id
      await this.aliasTrackingId().catch((error) => {
        console.error("Error aliasing user", error);
      });

      this.status = "available";
    } catch (e) {
      console.error("Error loading analytics", e);
      this.status = "error";
    }
  }

  private async aliasTrackingId() {
    const initialUrl = new URL(window.location.href);
    const remoteTrackingId = initialUrl.searchParams.get(trackingParamName);

    if (!remoteTrackingId) {
      return;
    }

    const currentTrackingId = this.getCookie(trackingCookieName);

    if (!currentTrackingId) {
      return;
    }

    await this.analyticsBrowser.alias(currentTrackingId, remoteTrackingId);
  }

  private getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length !== 2) {
      return;
    }

    const cookie = parts.pop();

    if (!cookie) {
      return;
    }

    return cookie.split(";").shift();
  }
}

export const analytics = new Analytics();
