import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "/index.css";
import { initializeI18n } from './i18n';
import App from "~/app";
import axios from "axios";

initializeI18n();

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [/.*/],
    }),
  ],
  // Necessary to track all Kratos raw errors
  maxValueLength: 5000,
});

axios.defaults.withCredentials = true;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
