import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const DEFAULT_LANGUAGE = "en";

const VARIANT_KEY = "sso-language-variant";
const LOCALIZED_VARIANT_VALUE = "localized";
const ORIGINAL_VARIANT = "original";

export const getOrCreateVariant = (): string => {
  let variant = localStorage.getItem(VARIANT_KEY);
  if (!variant) {
    variant = Math.random() < 0.5 ? LOCALIZED_VARIANT_VALUE : ORIGINAL_VARIANT; // 50/50 split
    localStorage.setItem(VARIANT_KEY, variant);
  }
  return variant;
};

export const getAppLanguage = () => {
  return i18n.language;
};

export const isLocalized = () => getOrCreateVariant() === LOCALIZED_VARIANT_VALUE;

export const initializeI18n = () => {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      fallbackLng: DEFAULT_LANGUAGE,
      debug: false,
      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
    });

  const variant = getOrCreateVariant();

  if (variant !== LOCALIZED_VARIANT_VALUE) {
    return;
  }

  const detector = new LanguageDetector();
  detector.init();
  let detectedLanguage = detector.detect();


  if (detectedLanguage === undefined) {
    return DEFAULT_LANGUAGE;
  }

  if (Array.isArray(detectedLanguage)) {
    detectedLanguage = detectedLanguage[0];
  }

  // Normalize detected language to the supported languages ( Map all possible variations of languages to a single language )
  const languageMap: Record<string, string> = {
    // Korean:
    ko: "ko",
    "ko-KR": "ko",
    "ko-KP": "ko",

    // Spanish:
    es: "es",
    "es-ES": "es",

    // Portuguese:
    pt: "pt",
    "pt-PT": "pt",
    "pt-BR": "pt",

    // Italian:
    it: "it",
    "it-IT": "it",

    // Traditional Chinese
    zh: "zh_TW",
    "zh-TW": "zh_TW",

    // French
    fr: "fr",
    "fr-FR": "fr",
    "fr-CA": "fr",

    // German
    de: "de",
    "de-DE": "de",
    "de-AT": "de",
    "de-CH": "de",

    // Turkish
    tr: "tr",
    "tr-TR": "tr",
  };

  // Get the normalized language
  const normalizedLanguage = languageMap[detectedLanguage] || DEFAULT_LANGUAGE; // Fallback to DEFAULT_LANGUAGE if not supported

  i18n.changeLanguage(normalizedLanguage);
};

// To downlaod the translations from lokalize
// >> lokalise2 file download --unzip-to /Users/aj/Code/sso/client/public/locales --format json --project-id <PROJECT_ID> --token <TOKEN>
